<template>
  <div>
    <div>
      <el-row
        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <el-col :span="3">
          <b>律师课时申请 - 已审核</b>
        </el-col>
        <el-col :span="2" :push="3" > 律师姓名: </el-col>
        <el-col :span="4" >
          <el-input
            placeholder="请输入"
            v-model="searchLawyerName"
            clearable
            size="small"
          >
          </el-input>
        </el-col>

        <el-col :span="6" :pull="2">
          <el-button size="small" icon="el-icon-search" type="primary" @click="handleSearch">搜索</el-button>
          <el-button size="small" @click="clearInput">重置</el-button>
        </el-col>
      </el-row>

      <el-table
        v-loading="loading"
        element-loading-text="加载中"
        ref="multipleTable"
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :default-sort = "{prop: 'updateTime', order: 'descending'}"
        :header-cell-style="{ background: '#eee', color: '#333' }">

        <el-table-column prop="updateTime" label="审核日期" align="center" width="140px"></el-table-column>
        <el-table-column prop="username" label="申报人" align="center" width="100px"></el-table-column>
        <el-table-column prop="type" label="申报类型" align="center"></el-table-column>
<!--        <el-table-column prop="detail" label="所属类别" align="center"></el-table-column>-->
<!--        <el-table-column prop="content" label="具体内容" align="center"></el-table-column>-->
        <el-table-column prop="score" label="申报学时/时长" align="center" width="120px"></el-table-column>
        <el-table-column prop="status" label="审核状态" align="center" width="180px"></el-table-column>
<!--        <el-table-column prop="status" label="审核结果" align="center"></el-table-column>-->
                <el-table-column prop="info" label="审核意见" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="130px" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-picture-outline"
              @click="handlePhoto(scope.row)">
              查看材料
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>

    </div>

    <el-dialog
      title="证明材料"
      :visible.sync="photoDialogVisible"

      width="70%"
      class="photoDialog"
    >
      <h2>具体类别</h2>
      <span>{{currentData.detail}}</span>
      <h2>活动内容</h2>
      <span>{{currentData.content}}</span>
      <el-row>

        <h2>图片</h2>
        <div v-for="item in url">
          <el-image
            style="width: 100px; height: 100px; margin-right: 10px; float: left"
            :src="item"
            :preview-src-list="url">
          </el-image>
        </div>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
import { request } from '@/network/network'
import {application_status_dict, application_status_options,application_detailTrain_dict, application_detailTrain_options,
  application_detailPublic_dict, application_detailPublic_options,application_type_dict, application_type_options
} from '@/utils/dict'
import  {formatDate} from '@/utils/date'
export default {
  name: 'Apply',
  data() {
    return {
      loading: true,
      total: 0,
      currentPage: 0,
      searchLawyerName: '',
      searchMap: '',

      multipleSelection: [],
      currentData: [],
      tableData: [],
      passDialogVisible: false,
      refuseDialogVisible: false,
      passSelectedDialogVisible: false,
      photoDialogVisible:false,

      application:"",

      url: [] ,
      adminInformation: JSON.parse(sessionStorage.getItem("information"))



    };
  },
  methods: {
    //分页插件绑定事件
    handleCurrentChange(val) {
      this.loading = true
      this.currentPage = val
      this.getApplications(this.searchMap, val)
    },
    //搜索按钮
    handleSearch() {
      this.searchMap = this.searchLawyerName
      this.getApplications(this.searchMap)

    },
    //重置搜索框
    clearInput() {
      this.searchTitle = ''
      this.searchMap = '',
        this.getApplications()
    },

    handlePhoto(row){
      this.currentData = row
      this.url = []
      // this.application = row

      let photos = JSON.parse(row.photo)
      if(row.photo ==null || row.photo.trim().length === 0){
       request({
          url: '/oss/getUrl',
          method: 'get',
          params: {
            appId: row.id,
          }
        }).then(res => {
          let ossPhotos = JSON.parse(res.data);
          for (const ossPhoto of ossPhotos) {
            // let path = 'https://api.shuobocloud.cn' + photo
            this.url.push(ossPhoto)
          }
        })
      }else{
        for (const photo of photos) {
          let path = 'https://law-1257239481.cos.ap-shanghai.myqcloud.com/static' + photo
          // let path = 'http://org.shuobocloud.cn' + photo
          this.url.push(path)
        }
      }


      this.photoDialogVisible=true
    },
    getApplications(name, page) {
      return request({
        url: '/application/applications',
        method: 'get',
        params: {
          name: name,
          page: page,
          status: 1, //传1可同时查2、3和4
          did:this.adminInformation.did
        }
      }).then(res => {
        this.total = res.data.total
        let temp = res.data.list
        this.tableData = temp.map(item => {
          if(item.info == null || item.info == ''){item.info="-"}
          item.status = application_status_dict[item.status];
          item.type =  application_type_dict[item.type];
          // item.startTime = formatDate(item.startTime)
          // item.endTime = formatDate(item.endTime)
          // item.createTime = formatDate(item.createTime)
          return item
        })
        this.loading = false
      })
    }
  },

  created() {
    this.getApplications()
  }
}
</script>

<style scoped>
.input-width {
  width: 80%;
}

</style>
