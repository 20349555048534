<template>

  <div>
    <div>
      <el-row
        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <el-col :span="3">
          <b>律师课时申请 - 待审核</b>
        </el-col>
        <el-col :span="3" :push="2">
          <el-button
            size="mini"
            type="success"
            icon="el-icon-s-check"
            @click="handlePassBatch">
            批量审核
          </el-button>
        </el-col>
        <el-col :span="2" :push="1" > 律师姓名:</el-col>
        <el-col :span="4" :pull="1">
          <el-input
            placeholder="请输入"
            v-model="searchLawyerName"
            clearable
            size="small"
          >
          </el-input>
        </el-col>

        <el-col :span="6" :pull="2">
          <el-button size="small" icon="el-icon-search" type="primary" @click="handleSearch">搜索</el-button>
          <el-button size="small" @click="clearInput">重置</el-button>
        </el-col>
      </el-row>
      <el-table
        v-loading="loading"
        element-loading-text="加载中"
        ref="multipleTable"
        :header-cell-style="{ background: '#eee', color: '#333' }"
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>

        <el-table-column prop="createTime" label="申报日期" align="center" width="140px"></el-table-column>
        <el-table-column prop="username" label="申报人" align="center" width="100px"></el-table-column>
<!--        <el-table-column prop="dname" label="所属律所" align="center" width="100px"></el-table-column>-->
        <el-table-column prop="type" label="申报类型" align="center"></el-table-column>
        <el-table-column prop="detail" label="所属类别" align="center"></el-table-column>
<!--        <el-table-column prop="content" label="具体内容" align="center"></el-table-column>-->
        <el-table-column prop="score" label="申报学时/时长" align="center" width="130px"></el-table-column>
        <el-table-column label="操作" align="center" width="120px" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button
                size="mini"

                type="warning"
                icon="el-icon-picture-outline"
                @click="handlePhoto(scope.row)">
                查看材料
              </el-button>
            </div>

            <div style="margin-top: 10px">
              <el-button
                size="mini"
                type="success"
                icon="el-icon-finished"
                @click="handlePass(scope.row)">
                通过申报
              </el-button>
            </div>

            <div style="margin-top: 10px">
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-document-delete"
                @click="handleRefuse(scope.$index, scope.row)">
                驳回申报
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>

    </div>


    <!--查看照片-->
    <el-dialog
      title="证明材料"
      :visible.sync="photoDialogVisible"

      width="70%"
      class="photoDialog"
    >
      <h2>具体类别</h2>
      <span>{{currentData.detail}}</span>
      <h2>活动内容</h2>
      <span>{{currentData.content}}</span>
      <el-row>

        <h2>图片</h2>
        <div v-for="item in url">
          <el-image
            style="width: 100px; height: 100px; margin-right: 10px; float: left"
            :src="item"
            :preview-src-list="url">
          </el-image>
        </div>
      </el-row>
    </el-dialog>

    <!--    通过审核-->
    <el-dialog
      title="审核"
      :visible.sync="passDialogVisible"
      :show-close="false"
      width="30%"
      class="passDialog"
    >
      <h1>同意通过吗</h1>
      <h3>姓名：{{application.username}}</h3>
      <h3>申请类型： {{application.type}}</h3>
      <h3>申请内容： {{application.detail}}</h3>
      <h3>申请学时/时长： {{application.score}}</h3>
      <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="passDialogVisible = false">取消</el-button>
            <el-button size="small" type="primary" @click="handlePassConfirm">确 定</el-button>
          </span>
    </el-dialog>

    <!--    驳回审核-->
    <el-dialog
      title="驳回"
      :visible.sync="refuseDialogVisible"
      :show-close="false"
      width="40%"
      class="refuseDialog"
    >
      <div>

        <h3>请填写驳回理由</h3>
        <el-divider></el-divider>

        <div style="margin: 20px 0;"></div>
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="refuseInfo"
          maxlength="30"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button  size="small" @click="refuseDialogVisible = false">取消</el-button>
            <el-button  size="small" type="primary" @click="handleRefuseConfirm">确 定</el-button>
          </span>
    </el-dialog>

    <!--    批量审核-->
    <el-dialog
      title="批量审核"
      :visible.sync="passBatchDialogVisible"
      :show-close="false"
      width="30%"
      class="passBatchDialog"
    >
      <h1>已选择</h1>
      <span v-for="item in multipleSelection">{{item.username}}  </span>
      <span>共{{multipleSelection.length}}位律师的申报</span>

      <h1>同意所选申报通过吗</h1>
      <span slot="footer" class="dialog-footer">
            <el-button  size="small" @click="passBatchDialogVisible = false">取消</el-button>
            <el-button  size="small" type="primary" @click="handlePassBatchConfirm">确 定</el-button>
          </span>
    </el-dialog>


  </div>
</template>

<script>
import { request } from '@/network/network'
import {application_status_dict, application_status_options,application_detailTrain_dict, application_detailTrain_options,
  application_detailPublic_dict, application_detailPublic_options,application_type_dict, application_type_options
} from '@/utils/dict'
import  {formatDate} from '@/utils/date'
export default {
  data() {
    return {
      loading: true,
      total: 0,
      currentPage: 0,
      searchLawyerName: '',
      searchMap: '',
      multipleSelection: [],
      currentData: [],
      tableData: [],
      passDialogVisible: false,
      refuseDialogVisible: false,
      passBatchDialogVisible: false,
      photoDialogVisible: false,

      url: [],


      application: '',
      auditApplicationId: '',
      refuseInfo: '',
      adminInformation: JSON.parse(sessionStorage.getItem("information"))

    }
  },
  methods: {
    //分页插件绑定事件
    handleCurrentChange(val) {
      this.loading = true
      this.currentPage = val
      this.getApplications(this.searchMap, val)
    },
    //搜索按钮
    handleSearch() {
      this.searchMap = this.searchLawyerName
      this.getApplications(this.searchMap)

    },
    //重置搜索框
    clearInput() {
      this.searchTitle = ''
      this.searchMap = '',
        this.getApplications()
    },
    //多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    // 通过审核
    handlePass(row) {
      this.application = row
      this.passDialogVisible = true
      this.auditApplicationId = row.id

    },
    handlePassConfirm() {
      let pass = { 'id': this.auditApplicationId, "status":2}
      this.passApplication(pass)
        .then((res) => {
          this.$message({
            message: '审核成功！',
            type: 'success',
            duration: 1000
          })
          this.getApplications();
          this.passDialogVisible = false
        })
        .catch((err) => {
          this.$message({
            message: '发生错误，审核失败！',
            type: 'error',
            duration: 1000
          })
          this.getApplications();
          this.passDialogVisible = false
        })

      this.auditApplicationId = ''
      this.application = {}
    },

    //驳回申请
    handleRefuse(index, row) {
      this.refuseDialogVisible = true
      this.auditApplicationId = row.id
    },
    handleRefuseConfirm(index, row) {
      let refuse = {"id":this.auditApplicationId, "info":this.refuseInfo, "status":1}
      this.refuseApplication(refuse)
        .then((res) => {
          this.$message({
            message: "审核成功！",
            type: "success",
            duration: 1000,
          });
          this.refuseInfo =''
          this.refuseDialogVisible = false
          this.getApplications();
          this.auditApplicationId=''
        })
        .catch((err) => {
          this.$message({
            message: "发生错误，审核失败！",
            type: "error",
            duration: 1000,
          });
          this.refuseInfo =''
          this.refuseDialogVisible = false
          this.getApplications();
          this.auditApplicationId=''
        });
    },


    handlePassBatch() {
      if(this.multipleSelection.length == 0){
        this.$message({
          message: "请先选择要批量审核的律师",
          type: "error",
          duration: 2000,
        });
      }
      else{this.passBatchDialogVisible = true}
    },
    handlePassBatchConfirm() {
      let batchApplicationList = []
      this.multipleSelection.map(item => {
        let obj = {};
        obj.id = item.id
        obj.status = 2
        batchApplicationList.push(obj)
      })
      console.log("this.batchApplicationList------------------")
      console.log(batchApplicationList)

      this.batchPass(batchApplicationList)
        .then((res) => {
          this.getApplications();
          this.$message({
            message: "批量审核成功！",
            type: "success",
            duration: 1000,
          });
        })
        .catch((err) => {
          this.getApplications();
          this.$message({
            message: "发生错误，批量审核失败！",
            type: "error",
            duration: 1000,
          });
        });
      this.multipleSelection=[]
      this.passBatchDialogVisible = false

    },
    handlePhoto(row) {
      this.currentData = row
      this.url = []

      let photos = JSON.parse(row.photo)
      if(row.photo ==null || row.photo.trim().length === 0){
      request({
          url: '/oss/getUrl',
          method: 'get',
          params: {
            appId: row.id,
          }
        }).then(res => {
          let ossPhotos = JSON.parse(res.data);
          for (const ossPhoto of ossPhotos) {
            // let path = 'https://api.shuobocloud.cn' + photo
            this.url.push(ossPhoto)
          }
        })
      }else{
        for (const photo of photos) {
          let path = 'https://law-1257239481.cos.ap-shanghai.myqcloud.com/static' + photo
          // let path = 'https://api.shuobocloud.cn' + photo
          this.url.push(path)
        }
      }

      this.photoDialogVisible=true

    },

    getApplications(name, page) {
      return request({
        url: '/application/applications',
        method: 'get',
        params: {
          did: this.adminInformation.did,
          name: name,
          page: page,
          status: 0,
        }
      }).then(res => {
        this.total = res.data.total
        let temp = res.data.list
        this.tableData = temp.map(item => {
          item.status = application_status_dict[item.status];
          item.type =  application_type_dict[item.type];
          // item.startTime = formatDate(item.startTime)
          // item.endTime = formatDate(item.endTime)
          // item.createTime = formatDate(item.createTime)
          return item
        })
        this.loading = false
      })
    },
    passApplication(application) {
      return request({
        url: '/application/passApplication',
        method: 'post',
        data: application
      })
    },
    batchPass(applications) {
      return request({
        url: '/application/batchPass',
        method: 'post',
        data: applications
      })
    },
    refuseApplication(application) {
      return request({
        url: '/application/refuseApplication',
        method: 'post',
        data: application
      })
    }
  },

  created() {
    this.getApplications()
  }

}
</script>

<style scoped>
.input-width {
  width: 80%;
}

</style>
